import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Brands = ({ brands }) => {
  return (
    <Box sx={{ mb: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Hemos colaborado con
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {brands.map((brand, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={brand.logo} alt={brand.name} style={{ maxWidth: '100px', maxHeight: '80px' }} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Brands;
