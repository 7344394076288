// DjProfile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Container,
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Box,
  Grid,
  CircularProgress,
  Chip,
  Tooltip,
} from '@mui/material';
import { getDjBio } from '../api/airtable';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import InfoIcon from '@mui/icons-material/Info';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReactPlayer from 'react-player';
import { styled } from '@mui/system';
import FooterLogo from '../components/FooterLogo';

function DjProfile() {
  const { djName } = useParams();
  
  // Hooks deben estar al inicio, sin condiciones
  const [djData, setDjData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [navValue, setNavValue] = useState('bio');
  const [bioExpanded, setBioExpanded] = useState(false);
  const [showAllLocales, setShowAllLocales] = useState(false); // Nuevo estado para manejar locales

  useEffect(() => {
    getDjBio(djName)
      .then((data) => {
        console.log('Datos del DJ:', data); // Para depuración
        setDjData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [djName]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
        Error al cargar los datos del DJ.
      </Typography>
    );
  }

  let photoUrl = null;
  if (djData.photo && djData.photo.length > 0) {
    photoUrl = djData.photo[0].url;
  }

  // Estilos personalizados
  const RoundedAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto',
    borderRadius: theme.spacing(2),
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  }));

  const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    position: 'relative',
  }));

  const Card = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
  }));

  const FloatingNav = styled(BottomNavigation)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: 600,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(3),
    zIndex: 1000,
  }));

  const selectedColor = '#D32F2F'; // Color representativo

  const handleBioExpandClick = () => {
    setBioExpanded(!bioExpanded);
  };

  const GradientText = styled(Typography)(({ theme }) => ({
    background: `linear-gradient(90deg, #D32F2F, #B71C1C)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }));

  // Ajusta la desestructuración según la estructura real de djData
  const {
    Lugar, // Ubicación
    Experiencia, // Años de experiencia
    'Precio Min': precioMin, // Precio mínimo
    'Precio Max': precioMax, // Precio máximo
    'Nombre Local': nombreLocal = [], // Array de nombres de locales
  } = djData || {}; // Ajusta si los campos están dentro de djData.fields

  // Asegúrate de que 'nombreLocal' sea un array
  const locales = Array.isArray(nombreLocal) ? nombreLocal : [nombreLocal].filter(Boolean);
  const hasMoreLocales = locales.length > 3;
  const displayedLocales = showAllLocales ? locales : locales.slice(0, 3);

  return (
    <div>
      {/* Contenedor principal */}
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        {/* Avatar */}
        {photoUrl && <RoundedAvatar src={photoUrl} alt={djData.name} />}

        {/* Nombre y Tagline */}
        <GradientText
          variant="h4"
          align="center"
          gutterBottom
          sx={{ mt: 2, fontWeight: 'bold' }}
        >
          {djData.name}
        </GradientText>
        <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
          {djData.tagline}
        </Typography>

        {/* Información Adicional */}
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          {/* Ubicación */}
          {Lugar && (
            <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 1 }}>
              <LocationOnIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
              {Lugar}
            </Typography>
          )}

          {/* Experiencia */}
          {Experiencia && (
            <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 1 }}>
              <WorkIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
              {Experiencia} años de experiencia
            </Typography>
          )}

          {/* Precio */}
          {(precioMin || precioMax) && (
            <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 1 }}>
              <AttachMoneyIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
              Precio: S/{precioMin} - S/{precioMax}
            </Typography>
          )}

{/* Locales */}
{locales.length > 0 && (
  <>
    <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 1, mb: 2 }}>
      Locales:
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {displayedLocales.map((local, index) => (
        <Chip
          key={index}
          label={local}
          sx={{
            m: 0.5,
            backgroundColor: 'rgba(255, 255, 255, 0.4)', // Color de fondo rojo
            color: '#fff', // Color de texto blanco
            borderRadius: '16px', // Bordes redondeados
            fontWeight: '400',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.4)', // Color de fondo más oscuro al pasar el ratón
            },
          }}
        />
      ))}
      {hasMoreLocales && !showAllLocales && (
        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {locales.slice(3).map((local, index) => (
                <Chip
                  key={index}
                  label={local}
                  sx={{
                    m: 0.5,
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    color: '#fff',
                    borderRadius: '16px',
                    fontWeight: '400',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    },
                  }}
                />
              ))}
            </Box>
          }
          arrow
          placement="top"
        >
          <Chip
            icon={<MoreHorizIcon />}
            label={`+${locales.length - 3}`}
            sx={{
              m: 0.5,
              backgroundColor: 'rgba(255, 255, 255, 0.4)', // Un rojo ligeramente diferente
              color: '#fff',
              borderRadius: '16px',
              fontWeight: '400',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
              },
            }}
            onClick={() => setShowAllLocales(true)}
          />
        </Tooltip>
      )}
    </Box>
  </>
)}
        </Box>

        {/* Sección de Biografía */}
        <Section id="bio">
          <Card>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              <InfoIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              Sobre mí
            </Typography>
            <Typography variant="body1" paragraph sx={{ lineHeight: 1.6 }}>
              {djData.bio.length > 200 ? (
                <>
                  {bioExpanded ? djData.bio : `${djData.bio.substring(0, 200)}...`}
                  <Button
                    onClick={handleBioExpandClick}
                    endIcon={<ExpandMoreIcon />}
                    sx={{
                      textTransform: 'none',
                      mt: 1,
                      color: selectedColor,
                      fontWeight: 'bold',
                    }}
                  >
                    {bioExpanded ? 'Leer menos' : 'Leer más'}
                  </Button>
                </>
              ) : (
                djData.bio
              )}
            </Typography>
          </Card>
        </Section>

        {/* Sección de Música */}
        <Section id="music">
          <Card>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              <MusicNoteIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              Música
            </Typography>
            {djData.musicUrl ? (
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <ReactPlayer
                  url={djData.musicUrl}
                  controls
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  config={{
                    soundcloud: {
                      options: {
                        visual: true,
                        auto_play: false,
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              <Typography variant="body1">Próximamente música disponible.</Typography>
            )}
          </Card>
        </Section>

        {/* Sección de Galería */}
        <Section id="gallery">
          <Card>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              <PhotoLibraryIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              Galería de Eventos
            </Typography>
            {djData.gallery && djData.gallery.length > 0 ? (
              <Box sx={{ position: 'relative' }}>
                <Grid container spacing={1}>
                  {djData.gallery.map((image, index) => (
                    <Grid item xs={6} key={index}>
                      <Box
                        component="img"
                        src={image.url}
                        alt={`Evento ${index + 1}`}
                        sx={{
                          width: '100%',
                          borderRadius: 2,
                          transition: 'transform 0.3s',
                          '&:hover': {
                            transform: 'scale(1.02)',
                          },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Typography variant="body1">No hay imágenes disponibles.</Typography>
            )}
          </Card>
        </Section>

        {/* Sección de Reserva */}
        <Section id="booking" sx={{ textAlign: 'center' }}>
          <Card>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              <ContactMailIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              Reserva
            </Typography>
            <Typography variant="body1" paragraph>
              ¿Listo para llevar tu evento al siguiente nivel?
            </Typography>
            <Button
              component="a"
              href={`https://api.whatsapp.com/send?phone=51945429543&text=¡Hola! Estoy interesado en contratar tu servicio.`}
              target="_blank"
              size="large"
              sx={{
                backgroundColor: selectedColor,
                color: '#fff',
                borderRadius: 2,
                textTransform: 'none',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  backgroundColor: '#B71C1C',
                },
              }}
            >
              Reserva mi show
            </Button>
            <FooterLogo />
          </Card>
        </Section>
      </Container>

      {/* Barra de navegación inferior */}
      <FloatingNav
        value={navValue}
        onChange={(event, newValue) => {
          setNavValue(newValue);
          const section = document.getElementById(newValue);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      >
        <BottomNavigationAction
          value="bio"
          icon={<InfoIcon fontSize="medium" />}
          sx={{
            color: navValue === 'bio' ? selectedColor : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="music"
          icon={<MusicNoteIcon fontSize="medium" />}
          sx={{
            color: navValue === 'music' ? selectedColor : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="gallery"
          icon={<PhotoLibraryIcon fontSize="medium" />}
          sx={{
            color: navValue === 'gallery' ? selectedColor : 'inherit',
          }}
        />
        <BottomNavigationAction
          value="booking"
          icon={<ContactMailIcon fontSize="medium" />}
          sx={{
            color: navValue === 'booking' ? selectedColor : 'inherit',
          }}
        />
      </FloatingNav>
    </div>
  );
}

export default DjProfile;
