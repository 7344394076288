import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
      <Typography variant="h4" gutterBottom>
        ¡Lleva tu fiesta al siguiente nivel!
      </Typography>
      <Typography variant="h6" sx={{ color: '#A6A5A6', mb: 4 }}>
      Mejoramos la conexión entre la gente y los DJs.
      </Typography>
      <Button 
        component={Link} 
        to="https://api.whatsapp.com/send?phone=51945429543&text=%C2%A1Hola%20Roberto!%20Quiero%20ver%20una%20demo%20de%20la%20app%20para%20DJs%20%F0%9F%8E%B6%F0%9F%94%A5" 
        variant="outlined" 
        color="secondary" 
        size="large"
        sx={{ color:'#ffffff', backgroundColor:'#115beb',borderRadius: 4, }}
      >
        ¡Contáctanos!
      </Button>
    </Box>
  );
};

export default HeroSection;
