import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography, Button, Box, CircularProgress, IconButton
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { motion, useMotionValue, useTransform } from 'framer-motion';

const SongCard = ({ song, handleDelete, loading }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Ya la puse');

  // Motion value for x-axis dragging
  const x = useMotionValue(0);

  // Cambia el color del botón de manera progresiva y fluida
  const buttonBackgroundColor = useTransform(x, [-80, 0], ['#f44336', 'rgb(244, 67, 54)']); // Rojo a verde
  const cardBackgroundColor = useTransform(x, [-200, 0], ['#f44336', '#ffffff']); // Color de la tarjeta
 
  // Cambio de texto basado en el desplazamiento
  useEffect(() => {
    x.on('change',(latest) => {
      if (latest < -50) {
        setButtonLabel('No se pondrá');
      } else {
        setButtonLabel('Ya la puse');
      }
    });
  }, [x]);

  const handleDragEnd = (event, info) => {
    // Si el usuario desliza más de 100px a la izquierda, eliminar la canción
    if (info.offset.x < -100) {
      handleDelete(song.id, 'No la quiero poner');
    }
    setIsDragging(false);
  };

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={handleDragEnd}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        x,
      }}
      whileTap={{ scale: 0.95 }}  // Para que el usuario sienta la respuesta táctil
    >
      <Card 
        component={motion.div} 
        sx={{
          borderRadius: 5,
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150px',  // Asegura que el tamaño sea adecuado en pantallas pequeñas
        }}
        style={{
          backgroundColor: cardBackgroundColor, // Cambio progresivo de color
          color: '#fff',
        }}
      >
        {/* Botón de eliminar en la parte superior derecha */}
        <IconButton
          size="small"
          sx={{ position: 'absolute', top: 8, right: 8, color: "#f44336", touchAction: 'none' }} // Sin gestos innecesarios
          onClick={() => handleDelete(song.id, 'No la quiero poner')}
          disabled={loading}
        >
          <CancelOutlinedIcon fontSize="small" />
        </IconButton>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
            {song.fields['Song Name']}
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {song.fields['Artist']}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
          <Button
            variant="contained"
            startIcon={<PlaylistPlayIcon />}
            sx={{ 
              borderRadius: 5, 
              flexGrow: 1,
              backgroundColor: buttonBackgroundColor.get(), // Cambio dinámico de color
              color: '#fff',
              minHeight: '50px',  // Botón lo suficientemente grande para dispositivos móviles
              transition: 'background-color 0.0s ease',  // Transición suave
              fontSize: '1rem'  // Texto más grande para pantallas móviles
            }}
            onClick={() => handleDelete(song.id, 'Se puso')}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : buttonLabel} {/* Texto dinámico */}
          </Button>
        </Box>
      </Card>
    </motion.div>
  );
};

export default SongCard;
