import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, List, ListItem, ListItemText, Paper, Container, Typography, CircularProgress
} from '@mui/material';
import { searchSongs } from '../api/deezer';
import { createKaraokeSongRequest, getKaraokePlaceInfo } from '../api/airtable';
import { useParams } from 'react-router-dom';
import KaraokePlaceInfoSection from '../components/KaraokePlaceInfoSection';
import KaraokeSelectedSongSection from '../components/KaraokeSelectedSongSection';
import FooterLogo from '../components/FooterLogo';
import KaraokeSongRequestModal from '../components/KaraokeSongRequestModal';

const KaraokeSongRequestForm = () => {
  const { formId } = useParams();
  const [query, setQuery] = useState('');
  const [tableNumber, setTableNumber] = useState(''); // Estado para el número de mesa
  const [tableNumberError, setTableNumberError] = useState(''); // Estado para el error del número de mesa
  const [results, setResults] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [placeInfo, setPlaceInfo] = useState({});
  const [loadingPlaceInfo, setLoadingPlaceInfo] = useState(true);

  useEffect(() => {
    const fetchPlaceInfo = async () => {
      try {
        const info = await getKaraokePlaceInfo(formId);
        setPlaceInfo(info);
      } catch (error) {
        console.error('Error al obtener información del lugar de karaoke:', error);
      } finally {
        setLoadingPlaceInfo(false);
      }
    };
    fetchPlaceInfo();
  }, [formId]);

  // Función para validar el número de mesa
  const validateTableNumber = (number) => {
    const regex = /^\d+$/; // Solo dígitos
    if (!regex.test(number)) {
      return 'Por favor, ingresa un número de mesa válido (solo números)';
    }
    return '';
  };

  const isTableNumberValid = () => {
    return validateTableNumber(tableNumber.trim()) === '';
  };

  const handleTableNumberChange = (e) => {
    const number = e.target.value;
    setTableNumber(number);
    const error = validateTableNumber(number);
    setTableNumberError(error);
  };

  const handleSearch = useCallback(async () => {
    const tableNumberValidationError = validateTableNumber(tableNumber.trim());
    if (tableNumberValidationError) {
      setTableNumberError(tableNumberValidationError);
      return;
    }
    if (!query.trim()) {
      setError('El campo de búsqueda no puede estar vacío');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const songs = await searchSongs(query);
      setResults(songs);
    } catch (error) {
      console.error('Error al buscar canciones:', error);
      setError('Hubo un error al buscar canciones. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [query, tableNumber]);

  const handleSelect = useCallback((song) => {
    setSelectedSong(song);
    setResults([]);
    setQuery('');
  }, []);

  const handleSubmit = useCallback(async () => {
    const tableNumberValidationError = validateTableNumber(tableNumber.trim());
    if (!selectedSong || tableNumberValidationError) {
      setTableNumberError(tableNumberValidationError);
      setError('Por favor, completa todos los campos correctamente');
      return;
    }
    setLoading(true);
    try {
      await createKaraokeSongRequest(
        formId,
        selectedSong.title,
        selectedSong.artist.name,
        tableNumber.trim()
      );
      setOpen(true);
    } catch (error) {
      console.error('Error al agregar la canción:', error);
      setError('Hubo un error al agregar la canción. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  }, [formId, selectedSong, tableNumber]);

  const handleCancel = () => {
    setSelectedSong(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSong(null);
    setTableNumber(''); // Limpiar el número de mesa
    setTableNumberError('');
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <KaraokePlaceInfoSection placeInfo={placeInfo} loadingPlaceInfo={loadingPlaceInfo} />
        <Typography variant="h6" component="h2" sx={{ marginBottom: 2, textAlign: 'center', fontSize:'1.1rem' }}>
          ¿Qué canción quieres cantar?
        </Typography>

        {/* Campo para ingresar el número de mesa */}
        {!selectedSong && (
          <>
            <TextField
              fullWidth
              label="Nº de mesa"
              value={tableNumber}
              onChange={handleTableNumberChange}
              sx={{
                marginBottom: '1rem',
                "& .MuiOutlinedInput-root": {
                  color: "#ffffff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C2C2C2",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                  },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#C2C2C2",
                  "&.Mui-focused": {
                    color: "#868686",
                  },
                },
              }}
              error={!!tableNumberError}
              helperText={tableNumberError}
            />

            <TextField
              fullWidth
              label="Nombre de la canción"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                marginBottom: '2rem',
                "& .MuiOutlinedInput-root": {
                  color: "#ffffff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C2C2C2",
                  },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C2C2C2",
                    },
                  },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#C2C2C2",
                  "&.Mui-focused": {
                    color: "#868686",
                  },
                },
              }}
              error={!!error && !query.trim()}
              helperText={!!error && !query.trim() ? 'Por favor, ingresa el nombre de la canción' : ''}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ marginTop: -1, marginBottom: 2, backgroundColor: '#54A772' }}
              disabled={loading || !isTableNumberValid() || !tableNumber.trim()}
            >
              {loading ? <CircularProgress size={24} /> : 'Buscar'}
            </Button>
          </>
        )}
        <List>
          {results.map((song, index) => (
            <ListItem key={index} onClick={() => handleSelect(song)}>
              <ListItemText primary={song.title} secondary={song.artist.name} />
            </ListItem>
          ))}
        </List>
        {selectedSong && (
          <KaraokeSelectedSongSection
            selectedSong={selectedSong}
            tableNumber={tableNumber}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            loading={loading}
            disabled={!isTableNumberValid() || !tableNumber.trim()}
          />
        )}
        <FooterLogo />
      </Paper>
      <KaraokeSongRequestModal
        open={open}
        handleClose={handleClose}
        placeInfo={placeInfo}
        tableNumber={tableNumber}
      />
    </Container>
  );
};

export default KaraokeSongRequestForm;
