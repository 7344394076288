import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const Testimonials = ({ testimonials }) => {
  return (
    <Box id="testimonials" sx={{ mb: 5 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Esto dicen nuestros clientes
      </Typography>
      <Box sx={{ maxWidth: 700, margin: '0 auto' }}>
        <Carousel
          autoPlay
          animation="slide"
          indicators={false}
          sx={{ height: '350px' }}
        >
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                p: 3,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '350px',
              }}
            >
              <Avatar
                src={testimonial.image}
                alt={testimonial.name}
                sx={{ width: 80, height: 80, marginBottom: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                {testimonial.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontStyle: 'italic', color: '#555', px: 2 }}
              >
                {testimonial.text}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Testimonials;
