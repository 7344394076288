import Airtable from 'airtable';

const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
const base = new Airtable({ apiKey }).base(baseId);

/* ----------------------------------------
   Funciones relacionadas con DJ
---------------------------------------- */

// Función para obtener los registros de canciones del DJ
export const getDJRecords = async (djViewId) => {
  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base(djViewId)
      .select({
        maxRecords: 100,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            recordsArray.push({ id: record.id, fields: record.fields });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error('Error al obtener los registros del DJ:', err);
            reject(err);
          } else {
            resolve(recordsArray);
          }
        }
      );
  });
};

// Función para crear una solicitud de canción para el DJ
export const createSongRequest = async (formId, songTitle, artistName) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songTitle,
            'Artist': artistName,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al crear la solicitud de canción para el DJ:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para guardar una solicitud de canción para el DJ (al mover canciones entre tablas)
export const saveSongRequest = async (
  formId,
  songName,
  artistName,
  created,
  option = ''
) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songName,
            'Artist': artistName,
            'Time of request': created,
            'Option': option,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al guardar la solicitud de canción para el DJ:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para obtener información del DJ
export const getDJInfo = async (formId) => {
  return new Promise((resolve, reject) => {
    base("Database DJ's")
      .select({
        filterByFormula: `{ID Form} = "${formId}"`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la información del DJ:', err);
          reject(err);
        } else if (records.length > 0) {
          resolve(records[0].fields);
        } else {
          reject(new Error('No se encontró información para este DJ'));
        }
      });
  });
};

// Función para eliminar un registro de canción del DJ
export const deleteDJRecord = async (djViewId, recordId) => {
  return new Promise((resolve, reject) => {
    base(djViewId).destroy([recordId], function (err, deletedRecords) {
      if (err) {
        console.error('Error al eliminar el registro del DJ:', err);
        reject(err);
      } else {
        resolve(deletedRecords);
      }
    });
  });
};

// Función para obtener DJs por ubicación
export const getDJsByLocation = async (locationId) => {
  const records = await base("Database DJ's")
    .select({ filterByFormula: `{ID Home} = "${locationId}"` })
    .all();
  return records.map((record) => ({ id: record.id, fields: record.fields }));
};

// Función para actualizar un registro de DJ
export const updateDJRecord = async (recordId, fields) => {
  return new Promise((resolve, reject) => {
    base("Database DJ's").update(
      [
        {
          id: recordId,
          fields: fields,
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al actualizar el registro del DJ:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

/* ----------------------------------------
   Funciones relacionadas con Karaoke
---------------------------------------- */

// Función para obtener los registros de canciones del karaoke
export const getKaraokeRecords = async (karaokeViewId) => {
  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base(karaokeViewId)
      .select({
        maxRecords: 100,
        view: 'Grid view',
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            recordsArray.push({ id: record.id, fields: record.fields });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error('Error al obtener los registros del karaoke:', err);
            reject(err);
          } else {
            resolve(recordsArray);
          }
        }
      );
  });
};

// Función para crear una solicitud de canción para el karaoke (incluye el nombre del usuario)
export const createKaraokeSongRequest = async (
  formId,
  songTitle,
  artistName,
  tableNumber
) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songTitle,
            'Artist': artistName,
            'Table Number': tableNumber, // Cambiamos a 'Table Number'
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al crear la solicitud de canción de karaoke:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para guardar una solicitud de canción para el karaoke (al mover canciones entre tablas)
export const saveKaraokeSongRequest = async (
  formId,
  songName,
  artistName,
  created,
  tableNumber,
  option = ''
) => {
  return new Promise((resolve, reject) => {
    base(formId).create(
      [
        {
          fields: {
            'Song Name': songName,
            'Artist': artistName,
            'Time of request': created,
            'Table Number': tableNumber,
            'Option': option,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error('Error al guardar la solicitud de canción de karaoke:', err);
          reject(err);
        } else {
          resolve(records);
        }
      }
    );
  });
};

// Función para obtener la información del lugar de karaoke
export const getKaraokePlaceInfo = async (formId) => {
  return new Promise((resolve, reject) => {
    base('Database Karaokes')
      .select({
        filterByFormula: `{ID Form} = "${formId}"`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la información del lugar de karaoke:', err);
          reject(err);
        } else if (records.length > 0) {
          resolve(records[0].fields);
        } else {
          reject(new Error('No se encontró información para este lugar de karaoke'));
        }
      });
  });
};

// Función para eliminar un registro de canción del karaoke
export const deleteKaraokeRecord = async (karaokeViewId, recordId) => {
  return new Promise((resolve, reject) => {
    base(karaokeViewId).destroy([recordId], function (err, deletedRecords) {
      if (err) {
        console.error('Error al eliminar el registro del karaoke:', err);
        reject(err);
      } else {
        resolve(deletedRecords);
      }
    });
  });
};

/* Funciones para obtener información del DJ */

// Función para obtener la biografía del DJ
export const getDjBio = async (djName) => {
  return new Promise((resolve, reject) => {
    base('DJs Bio')
      .select({
        filterByFormula: `{username} = '${djName}'`,
        maxRecords: 1,
      })
      .firstPage((err, records) => {
        if (err) {
          console.error('Error al obtener la biografía del DJ:', err);
          reject(err);
        } else if (records.length > 0) {
          resolve(records[0].fields);
        } else {
          reject(new Error('No se encontró información para este DJ'));
        }
      });
  });
};

/* ----------------------------------------
   Otras funciones generales (si las hay)
---------------------------------------- */

// Si hay funciones compartidas o generales, se pueden colocar aquí.
