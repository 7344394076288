import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Avatar, Chip, Tooltip, Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';

const DJsSection = ({ djs }) => {
  const [showAllDJs, setShowAllDJs] = useState(false);

  const toggleShowDJs = () => {
    setShowAllDJs(!showAllDJs);
  };

  // Filtrar DJs con 'Precio Min' y 'Precio Max' completos
  const djsWithPrice = djs.filter(dj => dj.fields['Mostrar']);

  return (
    <Box id="djs" sx={{ mb: 5, textAlign: 'center' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Los DJs que mejor la ponen 🔊
      </Typography>
      <Typography variant="body1" sx={{ color: '#A6A5A6', mb: 4, fontSize:'1.1rem' }}>
        Encuentra el DJ perfecto para tu evento y crea experiencias inolvidables.
      </Typography>
      <Grid container spacing={4}>
        {(showAllDJs ? djsWithPrice : djsWithPrice.slice(0, 6)).map((dj, index) => {
          const locales = dj.fields['Nombre Local'] || [];
          const hasMoreLocales = locales.length > 3;

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper elevation={3} sx={{ p: 3, textAlign: 'center', borderRadius: 2, minHeight: '400px' }}>
                <Avatar
                  alt={dj.fields['Name']}
                  src={dj.fields['Foto'][0]?.url}
                  sx={{ width: 100, height: 100, margin: '0 auto 20px' }}
                />
                <Typography variant="h6" gutterBottom>
                  {dj.fields['Name']}
                </Typography>
                
                <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 2 }}>
                  <LocationOnIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                  {dj.fields['Lugar']} 
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mt: 2 }}>
                  <WorkIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                  {dj.fields['Experiencia']} años de experiencia
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <AttachMoneyIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                  Precio: S/{dj.fields['Precio Min']} - S/{dj.fields['Precio Max']} 
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Locales:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {locales.slice(0, 3).map((local, index) => (
                    <Chip key={index} label={local} sx={{ m: 0.5 }} />
                  ))}
                  {hasMoreLocales && (
                    <Tooltip
                      title={
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {locales.slice(3).map((local, index) => (
                            <Chip key={index} label={local} sx={{ m: 0.5 }} />
                          ))}
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <Chip
                        icon={<MoreHorizIcon />}
                        label={`+${locales.length - 3}`}
                        sx={{ m: 0.5 }}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Button
                  component="a"
                  href={`https://api.whatsapp.com/send?phone=51945429543&text=¡Hola! Estoy interesado en contratar el servicio de ${dj.fields['Name']}.`}
                  target="_blank"
                  variant="outlined"
                  sx={{ mt: 2, color: '#ffffff', backgroundColor:'#115beb',borderRadius: 4, }}
                >
                  Contactar
                </Button>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      {djsWithPrice.length > 6 && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" color="primary" onClick={toggleShowDJs}>
            {showAllDJs ? 'Mostrar menos' : 'Mostrar más'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DJsSection;
