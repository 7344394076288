import React, { useState, useEffect } from 'react';
import { 
  Container, Grid, Card, CardContent, Typography, Box, CircularProgress, 
  Switch, FormControlLabel, Avatar, Alert, Snackbar, Button, Dialog, DialogTitle, DialogContent, DialogActions 
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { getDJsByLocation, updateDJRecord } from '../api/airtable';
import { styled } from '@mui/system';

const AdminView = () => {
  const { id } = useParams();
  const [djs, setDjs] = useState([]);
  const [error, setError] = useState(null);
  const [loadingDJInfo, setLoadingDJInfo] = useState(true);
  const [loadingDJId, setLoadingDJId] = useState(null); // Estado de carga individual por DJ
  const [openSnackbar, setOpenSnackbar] = useState(false); // Estado para Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Estado para el diálogo de confirmación
  const [selectedDJ, setSelectedDJ] = useState(null); // DJ seleccionado para activar

  useEffect(() => {
    const fetchDJs = async () => {
      try {
        const djRecords = await getDJsByLocation(id);
        setDjs(djRecords);
        setLoadingDJInfo(false);
      } catch (error) {
        console.error('Error fetching DJs:', error);
        setError('Hubo un error al cargar los DJs. Por favor, inténtelo de nuevo.');
        setLoadingDJInfo(false);
      }
    };

    fetchDJs();
  }, [id]);

  const handleOpenConfirmDialog = (dj) => {
    setSelectedDJ(dj);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedDJ(null);
  };

  const handleToggleActive = async () => {
    setLoadingDJId(selectedDJ.id);
    try {
      const currentActiveDJ = djs.find(djRecord => !!djRecord.fields['ID Form']);

      const updatePromises = [];

      // Desactivar el DJ activo anterior
      if (currentActiveDJ && currentActiveDJ.id !== selectedDJ.id) {
        updatePromises.push(updateDJRecord(currentActiveDJ.id, { 'ID Form': '' }));
      }

      // Activar o desactivar el DJ seleccionado
      const newIDForm = currentActiveDJ?.id === selectedDJ.id ? '' : `f${id}m`;
      updatePromises.push(updateDJRecord(selectedDJ.id, { 'ID Form': newIDForm }));

      await Promise.all(updatePromises);

      // Actualizar la lista de DJs localmente
      const updatedDJs = djs.map(djRecord => {
        if (djRecord.id === selectedDJ.id) {
          return { ...djRecord, fields: { ...djRecord.fields, 'ID Form': newIDForm } };
        } else if (djRecord.id === currentActiveDJ?.id && currentActiveDJ.id !== selectedDJ.id) {
          return { ...djRecord, fields: { ...djRecord.fields, 'ID Form': '' } };
        } else {
          return djRecord;
        }
      });
      setDjs(updatedDJs);

      // Mostrar notificación de éxito
      setSnackbarMessage(`DJ ${selectedDJ.fields['Name']} activado correctamente.`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating DJ:', error);
      setError('Hubo un error al actualizar el DJ. Por favor, inténtelo de nuevo.');
      setSnackbarMessage('Error al actualizar el DJ.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoadingDJId(null);
      handleCloseConfirmDialog();
    }
  };

  // Estilos personalizados para las tarjetas
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#222',
    color: '#fff',
    borderRadius: 12,
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 0 20px rgba(0, 255, 0, 0.5)',
    },
  }));

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#111', color: '#fff', py: 2 }}>
      <Container maxWidth="md">
        <Typography variant="h4" fontWeight={700} align="center" gutterBottom mb={4}>
          ¿Cuál DJ quieres activar?
        </Typography>

        {loadingDJInfo ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 10 }}>
            <CircularProgress color="success" />
          </Box>
        ) : error ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : (
          <Grid container spacing={4}>
            {djs.map((dj, index) => {
              const isActive = !!dj.fields['ID Form'];
              const isLoading = loadingDJId === dj.id;

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <StyledCard
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'space-between',
                      border: isActive ? '2px solid #4caf50' : '2px solid transparent',
                      boxShadow: isActive ? '0 0 15px #4caf50' : '0 0 10px #333',
                    }}
                  >
                    <CardContent>
                      {dj.fields['Foto'] && dj.fields['Foto'].length > 0 ? (
                        <Avatar
                          src={dj.fields['Foto'][0].url}
                          alt={dj.fields['Name']}
                          sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }}
                        />
                      ) : (
                        <Avatar sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }}>
                          {dj.fields['Name'] ? dj.fields['Name'].charAt(0) : 'DJ'}
                        </Avatar>
                      )}
                      <Typography variant="h6" component="h2" align="center">
                        {dj.fields['Name']}
                      </Typography>
                    </CardContent>
                    <Box sx={{ p: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch 
                            checked={isActive} 
                            onChange={() => handleOpenConfirmDialog(dj)} 
                            color="success" 
                            disabled={isLoading}
                            inputProps={{ 'aria-label': `Activar o desactivar a ${dj.fields['Name']}` }}
                          />
                        }
                        label={
                          isLoading ? (
                            <CircularProgress size={24} color="success" />
                          ) : isActive ? 'Activo' : 'Inactivo'
                        }
                        labelPlacement="start"
                        sx={{ justifyContent: 'space-between', width: '100%' }}
                      />
                    </Box>
                  </StyledCard>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* Diálogo de confirmación */}
        <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Confirmar Activación</DialogTitle>
          <DialogContent>
            <Typography>
              ¿Estás seguro de que deseas activar a {selectedDJ?.fields['Name']}? Esto desactivará a los demás DJs.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
            <Button onClick={handleToggleActive} color="primary">Activar</Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

      </Container>
    </Box>
  );
};

export default AdminView;
