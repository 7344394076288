import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const KaraokeSongRequestModal = ({ open, handleClose, placeInfo, tableNumber }) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 300,
      bgcolor: 'background.paper',
      border: '1px solid #fff',
      boxShadow: 25,
      p: 4,
      borderRadius: 5,
    }}>
      <Typography variant="h6" component="h3" sx={{ fontSize: '1rem', textAlign:'center' }}>
        ¡Mesa Nº {tableNumber}, tu canción ha sido agregada!
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '1rem', textAlign:'center', marginTop: 1 }}>
        Prepárate para subir al escenario y brillar.
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleClose}
        sx={{ marginTop: 2, backgroundColor: '#54A772' }}
      >
        Agregar otra canción
      </Button>
      {placeInfo && placeInfo.InstagramLink && placeInfo.InstagramHandle && (
        <>
          <Typography variant="body1" sx={{ fontSize: '1rem', textAlign:'center', marginTop: 2 }}>
            ¡Síguenos en Instagram!
          </Typography>
          <Button
            component="a"
            href={placeInfo.InstagramLink}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth
            variant="outlined"
            sx={{ fontSize: '', marginTop: 1, color: '#ffffff', borderColor: '#ffffff' }}
          >
            <InstagramIcon /> {placeInfo.InstagramHandle}
          </Button>
        </>
      )}
    </Box>
  </Modal>
);

export default KaraokeSongRequestModal;
