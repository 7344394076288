import React from 'react';
import { Paper, Box, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const KaraokeSelectedSongSection = ({
  selectedSong,
  tableNumber,
  handleCancel,
  handleSubmit,
  loading,
  disabled,
}) => (
  <Paper elevation={1} sx={{ padding: 2, marginTop: -1, marginBottom: 2 }}>
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Canción seleccionada
        </Typography>
        <Typography variant="body1">
          <strong>{selectedSong.title}</strong> por <strong>{selectedSong.artist.name}</strong>
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          <strong>Nº de mesa:</strong> {tableNumber}
        </Typography>
      </Box>
      <IconButton onClick={handleCancel} aria-label="cancel">
        <CloseIcon />
      </IconButton>
    </Box>
    <Button
      fullWidth
      variant="contained"
      onClick={handleSubmit}
      sx={{ marginTop: 2, backgroundColor: '#54A772' }}
      disabled={loading || disabled}
    >
      {loading ? <CircularProgress size={24} /> : 'Agregar a la lista de karaoke'}
    </Button>
  </Paper>
);

export default KaraokeSelectedSongSection;
